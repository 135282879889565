import { ref, computed } from 'vue';
import { useSdk } from './use-mycure';
import { format } from 'date-fns';
import { useFacilities } from './use-facilities';
import { omit } from 'lodash';

const BILLING_INVOICES_SERVICE_NAME = 'billing-invoices';
const BILLING_ITEMS_SERVICE_NAME = 'billing-items';
const BILLING_PAYMENTS_SERVICE_NAME = 'billing-payments';
const BILLING_MERCHANTS_SERVICE_NAME = 'billing/merchants';
const BILLING_INVOICES_SERVICE_NAME_NEXT = 'billing/invoices';

function mapBillingItems (item) {
  const service = item?.$populated?.service;
  const icd10Codes = service?.codings?.filter((coding) => coding.system === 'icd-10');
  const cptCodes = service?.codings?.filter((coding) => coding.system === 'cpt-code');

  return {
    ...omit(item, ['$populated']),
    service: item?.$populated?.service,
    icd10Codes,
    cptCodes,
    formattedCreatedAt: item?.createdAt ? format(new Date(item.createdAt), 'MMM dd, yyyy') : null,
  };
}

function mapBillingInvoice (invoice) {
  return {
    ...invoice,
    formattedCreatedAt: invoice?.createdAt ? format(new Date(invoice.createdAt), 'MMM dd, yyyy') : null,
    formattedDueDate: invoice?.dueDate ? format(new Date(invoice.dueDate), 'MMM dd, yyyy') : null,
  };
}

function mapBillingPromo (promo) {
  return {
    ...promo,
    formattedCreatedAt: promo?.createdAt ? format(new Date(promo.createdAt), 'MMM dd, yyyy') : null,
    formattedValidityStartAt: promo?.validityStartAt ? format(new Date(promo.validityStartAt), 'MMM dd, yyyy') : null,
    formattedValidityEndAt: promo?.validityEndAt ? format(new Date(promo.validityEndAt), 'MMM dd, yyyy') : null,
  };
}

export function useBillingInvoices () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  const billingInvoices = ref([]);
  const activeInvoice = ref(null);
  const activeInvoiceStatus = computed(() => activeInvoice.value?.status);

  async function listItems (invoice = null, opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      invoice,
      $sort: {
        createdAt: -1,
      },
      ...opts,
    };
    const result = await sdk?.list(BILLING_INVOICES_SERVICE_NAME, query);
    billingInvoices.value = result.data.map(mapBillingInvoice);
  }

  async function getItem (id) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      id,
    };
    const result = await sdk?.get(BILLING_INVOICES_SERVICE_NAME, query);
    return mapBillingInvoice(result);
  }

  async function getActiveInvoice (id) {
    const result = await getItem(id);
    activeInvoice.value = result;
    return result;
  }

  async function captureInvoice (id) {
    try {
      return await sdk?.create(`${BILLING_INVOICES_SERVICE_NAME_NEXT}/${id}:capture`);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  
  async function voidInvoice (id) {
    return sdk?.create(`${BILLING_INVOICES_SERVICE_NAME_NEXT}/${id}:void`);
  }

  return {
    billingInvoices,
    activeInvoice,
    activeInvoiceStatus,
    listItems,
    getItem,
    getActiveInvoice,
    captureInvoice,
    voidInvoice,
  };
}

export function useBillingItems () {
  const sdk = useSdk();

  const billingItems = ref([]);
  const totalPaid = computed(() => {
    const total = billingItems.value?.reduce((acc, item) => acc + (item.paid || 0), 0);
    return total.toFixed(2);
  });
  const totalPretax = computed(() => {
    const total = billingItems.value?.reduce((acc, item) => acc + item.amountToPayCalculations?.totalPretax, 0);
    return total.toFixed(2);
  });
  const totalGross = computed(() => {
    const total = billingItems.value?.reduce((acc, item) => acc + item.amountToPayCalculations?.total, 0);
    return total.toFixed(2);
  });
  const totalTax = computed(() => {
    const total = billingItems.value?.reduce((acc, item) => acc + item.amountToPayCalculations?.tax, 0);
    return total.toFixed(2);
  });
  const totalAmountDue = computed(() => {
    return (totalGross.value - totalPaid.value).toFixed(2);
  });
  const { getActiveFacility } = useFacilities();

  async function listItems (invoice = null) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      invoice,
      $sort: {
        createdAt: -1,
      },
      $populate: {
        service: {
          service: 'services',
          localKey: 'ref',
          foreignKey: 'id',
        },
      },
    };
    const result = await sdk?.list(BILLING_ITEMS_SERVICE_NAME, query);
    billingItems.value = result.data?.map(mapBillingItems);
  }

  async function createItem (data) {
    const payload = {
      ...data,
    };
    const result = await sdk?.create(BILLING_ITEMS_SERVICE_NAME, payload);
    billingItems.value.unshift(result);
    return result;
  }

  async function removeItem (id) {
    await sdk?.delete(BILLING_ITEMS_SERVICE_NAME, id);
    const index = billingItems.value.findIndex((item) => item.id === id);
    billingItems.value.splice(index, 1);
  }

  return {
    billingItems,
    totalPaid,
    totalPretax,
    totalGross,
    totalTax,
    totalAmountDue,
    listItems,
    createItem,
    removeItem,
  };
}

export function useBillingPayments () {
  const sdk = useSdk();

  async function createItem (data) {
    const payload = {
      ...data,
    };
    const result = await sdk?.create(BILLING_PAYMENTS_SERVICE_NAME, payload);
    return result;
  }

  return {
    createItem,
  };
}

export function useBillingPromos () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();
  const activeFacility = getActiveFacility();
  const facilityId = activeFacility.value?.id;
  const billingPromosList = ref([]);

  async function listPromos () {
    const query = {
      organization: facilityId,
      $sort: {
        createdAt: -1,
      },
    };
    const result = await sdk?.list('billing-promos', query);
    billingPromosList.value = result.data.map(mapBillingPromo);
  };

  async function addPromo (payload) {
    const query = {
      organization: facilityId,
      ...payload,
    };
    const result = await sdk?.create('billing-promos', query);
    billingPromosList.value.push(mapBillingPromo(result));
  }

  async function deletePromo (id) {
    await sdk?.delete('billing-promos', id);
    const index = billingPromosList.value.findIndex((item) => item.id === id);
    billingPromosList.value.splice(index, 1);
  }

  return {
    billingPromosList,
    listPromos,
    addPromo,
    deletePromo,
  };
};

export function useBillingPaymentIntent () {
  const sdk = useSdk();
  const SERVICE_NAME = 'billing-payment-intents';

  async function createItem (opts) {
    const payload = Object.assign({}, opts);
    return sdk?.create(SERVICE_NAME, payload);
  }

  async function getItems (opts) {
    const query = Object.apply({}, opts);
    return sdk?.find(SERVICE_NAME, query);
  }

  /**
   * @param {Object} opts
   * @param {String} opts.customer
   * @returns Array of payment intents
   */
  async function getPendingItems (opts) {
    const query = Object.assign({}, opts, {
      'items.ref': 'standalone_license_perpetual',
      status: 'pending',
    });
    return sdk?.get(SERVICE_NAME, query);
  }

  /**
   * @param {Object} opts
   * @param {String} opts.customer
   * @returns Array of payment intents
   */
  async function getPaidItems (opts) {
    const query = Object.assign({}, opts, {
      'items.ref': 'standalone_license_perpetual',
      status: 'paid',
    });
    return sdk?.get(SERVICE_NAME, query);
  }

  async function capturePayment (id, opts) {
    const payload = Object.assign({
      capture: true,
    }, opts);
    return sdk?.update(SERVICE_NAME, id, payload);
  }

  return {
    createItem,
    getItems,
    getPendingItems,
    getPaidItems,
    capturePayment,
  };
}

export function useBillingMerchant () {
  const sdk = useSdk();
  const config = ref(null);
  const loading = ref(false);
  const balance = computed(() => Object.assign({
    generatedAt: Date.now(),
    available: [],
    pending: [],
  }, config.value?.balance));

  const loadMerchantConfig = async () => {
    loading.value = true;
    try {
      const currentUser = await sdk?.currentUser();
      if (!currentUser?.uid) throw new Error('No authenticated user found.');
      // requery using direct GET to trigger account syncing
      const result = await sdk?.get(BILLING_MERCHANTS_SERVICE_NAME, {
        $limit: 1,
        ownerType: 'account',
        owner: currentUser.uid,
      }).then(r => r?.id && sdk?.get(`${BILLING_MERCHANTS_SERVICE_NAME}/${r.id}`));
      config.value = result;
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };
  
  const loadMerchantBalance = async () => {
    try {
      if (!config.value) await loadMerchantConfig();
      if (!config.value) return;
      loading.value = true;

      const result = await sdk?.create(`billing/merchants/${config.value.id}:balance`);
      config.value.balance = result;
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const generateUrlToOnboarding = async (opts) => {
    if (!config.value) await loadMerchantConfig();
    if (!config.value) {
      const currentUser = await sdk?.currentUser();
      if (!currentUser?.uid) throw new Error(`No authenticated user found.`);
      const created = await sdk?.create(BILLING_MERCHANTS_SERVICE_NAME, {
        ownerType: 'account',
        owner: currentUser.uid,
      });
      config.value = created;
    }
    const onboarding = await sdk?.create(`${BILLING_MERCHANTS_SERVICE_NAME}/${config.value.id}:onboard`, {
      returnUrl: opts?.returnUrl || window.location.href,
      cancelUrl: opts?.cancelUrl || window.location.href,
    });
    return { url: onboarding.redirectUrl };
  };

  const generateUrlToDashboard = async () => {
    if (!config.value) throw new Error(`No merchant config found.`);
    const dashboard = await sdk?.create(`${BILLING_MERCHANTS_SERVICE_NAME}/${config.value.id}:dashboard`, {});
    return { url: dashboard.redirectUrl };
  };

  return {
    loading,
    config,
    balance,
    loadMerchantConfig,
    loadMerchantBalance,
    generateUrlToOnboarding,
    generateUrlToDashboard,
  };
}

export function useBillingPrices () {
  const sdk = useSdk();

  async function getPriceByTags (tags) {
    if (!tags?.length) throw new Error('No tags provided.');
    const query = {
      tags: { $all: tags },
    };
    const result = await sdk?.get('billing/prices', query);
    return result;
  }

  return {
    getPriceByTags,
  }; 
}
